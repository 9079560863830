<template>
  <div class="client-api-top">
    <el-row :gutter="30">
      <el-col class="pie-col" :span="12">
        <div class="chart-box">
          <pie-chart ref="piechart" :chart-config="pieConfig"></pie-chart>
        </div>
      </el-col>
      <el-col :span="9">
        <el-col
          :span="12"
        >
          <p
          >{{totalInfo.name}}: {{totalInfo.value}}</p>
        </el-col>
        <el-col
          :span="12"
        >
          <p
          >在线率: {{onlineRate}}</p>
        </el-col>
        <el-col :span="8"
                v-for="(item, index) in connectList"
                :key="index"
        >
          <p
          >{{item.name}}: {{item.value}}</p>
        </el-col>
      </el-col>
      <el-col :span="3">
        <el-button
          type="success"
          @click.native="refreshHandle"
        >刷新</el-button>
      </el-col>
      <el-col>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: 'rgba(240,240,240,1)' }"
        >
          <el-table-column
            prop="title"
            label="类型"
            width="180">
          </el-table-column>
          <el-table-column
            label="账户列表">
            <template slot-scope="scope">
              <span
                class=""
                v-for="(item, k) in scope.row.list"
                :key="k"
              >{{item}},</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import pieChart from '@/components/monitor/apiWatch/detail/pieChart'
  import { GetTradeConnectionStat } from '@/api/system/apiWatch'
  export default {
    name: 'pageTop',
    components: {
      pieChart
    },
    data() {
      return {
        pieConfig:{
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          // legend: {
          //   left: 'center',
          //   bottom: '10',
          //   data: ['Industries', 'Technology', 'Forex', 'Gold', 'Forecasts']
          // },
          calculable: true,
          series: [
            {
              type: 'pie',
              radius: '70%',
              center: ['50%', '50%'],
              data: [],
              animationEasing: 'cubicInOut',
              animationDuration: 1000
            }
          ]
        },
        totalInfo:{
          name: '',
          value: ''
        },
        connectList: [],
        tableData:[],
        isClickRefreshBtn: false,
        processList:[],
        reconnList:[],
        invalidList:[]
      }
    },
    mounted(){
      this.getUserConnectData();
    },
    computed:{
      onlineRate(){
        return ((this.connectList[0] && this.connectList[0].value / this.totalInfo.value) * 100 ).toFixed(1)+ '%';
      }
    },
    methods: {

      getUserConnectData() {
        GetTradeConnectionStat({
          hostId: this.$route.query.hostId,
        }).then((res) => {
          /*
        public int total;
        public int connected;
        public int process;
        public int reconn;
        public int cold;
        public int invalid;

        public List<string> processList;
        public List<string> reconnList;
        public List<string> coldList;
        public List<string> invalidList;
          总用户
          已连接
          连接中
          待重连
          冷却中
          已失效
          * */
          if(!res) return;
          this.totalInfo =
            {
              name: '总用户数',
              value: res.Data.total,
            };
          this.connectList = [
            {
              name: '已连接',
              value: res.Data.connected,
              itemStyle: {
                color: 'green'
              }
            },
            {
              name: '连接中',
              value: res.Data.process,
              itemStyle: {
                color: 'blue'
              }
            },
            {
              name: '断开连接',
              value: res.Data.disConnected,
              itemStyle: {
                color: 'purple'
              }
            },
            {
              name: '待重连',
              value: res.Data.reconn,
              itemStyle: {
                color: 'gray'
              }
            },
            {
              name: '冷却中',
              value: res.Data.cold,
              itemStyle: {
                color: 'yellow'
              }
            },
            {
              name: '已失效',
              value: res.Data.invalid,
              itemStyle: {
                color: 'red'
              }
            },
          ];
          this.pieConfig.series[0].data = this.connectList;
          this.$refs.piechart.set();
          let copyTableData = [
            {
              title: '连接中',
              list: res.Data.processList
            },
            {
              title: '断开连接',
              list: res.Data.disConnectedList
            },
            {
              title: '待重连',
              list: res.Data.reconnList,
            },
            {
              title: '冷却中',
              list: res.Data.coldList
            },
            {
              title: '已失效',
              list: res.Data.invalidList
            }
          ]
          this.tableData = copyTableData;
        })
      },

      refreshHandle(){
        if(this.isClickRefreshBtn) return this.$message({
          message: '间隔5秒请稍后再试'
        });
        this.isClickRefreshBtn = true;
        this.getUserConnectData();
        setTimeout(() => {
          this.isClickRefreshBtn = false;
        },5000)
      }
    }
  }
</script>

<style scoped lang="less">
  .client-api-top {
    margin-bottom: 50px;
    .btn-box {
      padding: 20px 0;
      text-align: center;
    }
    .pie-col {
      padding: 20px 0;
      .chart-box {
        height: 300px;
        width: 100%;
      }
    }
    .item-span {
      margin: 0 20px;
    }
  }
</style>
